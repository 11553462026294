<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('ditfAppTradeFairManages.return_and_refund') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-overlay :show="loading">
            <b-form id="form" @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <b-col sm="4">
                  <ValidationProvider name="Year" vid="year" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="year">
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        :options="yearList"
                        v-model="search.year"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="Fair Name" vid="fair_id" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="fair_id"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.fair_name') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        disabled
                        plain
                        id="fair_id"
                        :options="dhakaFairList"
                        v-model="search.fair_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="Circular type" vid="circular_type" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="circular_type">
                      <template v-slot:label>
                        {{ $t('ditfTradeFairManages.circular_type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="circular_type"
                        :options="circularTypeList"
                        v-model="search.circular_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <b-button type="submit" size="sm" variant="primary"><i class="ri-search-line"></i> {{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-overlay>
        </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('ditfAppTradeFairManages.return_and_refund') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(processing_fee)="data">
                    {{ $n(data.item.processing_fee, { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(floor_price)="data">
                    {{ $n(data.item.floor_price) }}
                  </template>
                  <template v-slot:cell(stall_qty)="data">
                    {{ $n(data.item.stall_numbers.length, { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(security_perc)="data">
                    {{ $n(data.item.security_perc) }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-info" v-if="data.item.status === 2">
                      {{ $t('globalTrans.approved') }}
                    </span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant="iq-bg-success mr-1" size="sm" @click="returnRefundData(data.item)" class="action-btn edit"><i class="ri-eye-fill"></i></b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { returnRefundList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import AddressHelper from '@/utils/area-type-address'

export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      valid: '',
      sortBy: '',
      search: {
        year: 0,
        fair_id: 0,
        circular_type: 1,
        limit: 20
      },
      dhakaFairList: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '9%' } },
        { labels: 'ditfAppTradeFairManages.stall_type', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '9%' } },
        { labels: 'ditfAppTradeFairManages.cat_name', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '9%' } },
        { labels: 'ditfConfig.size', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '10%' } },
        { labels: 'ditfConfig.price_type', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '10%' } },
        { labels: 'ditfConfig.app_porcess_fee', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
        { labels: 'ditfConfig.fare_floor', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '15%' } },
        { labels: 'ditfConfig.security_perc', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 9 }
      ],
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.search.year = new Date().getFullYear()
    this.loadData()
  },
  watch: {
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
      }
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    selectedItem: function () {
      if (this.selectedItem.length === this.listData.length) {
        this.selectAll = true
      }
      if (this.selectedItem.length < this.listData.length) {
        this.selectAll = false
      }
    }
  },
  computed: {
    listData: function () {
      const tmpData = this.$store.state.list
      return JSON.parse(JSON.stringify(tmpData))
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'stall_type_bn' },
          { key: 'cat_name_bn' },
          { key: 'size_bn' },
          { key: 'price_type_bn' },
          { key: 'processing_fee' },
          { key: 'floor_price' },
          { key: 'security_perc' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'stall_type_en' },
          { key: 'cat_name_en' },
          { key: 'size_en' },
          { key: 'price_type_en' },
          { key: 'processing_fee' },
          { key: 'floor_price' },
          { key: 'security_perc' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    returnRefundData (item) {
      this.$router.push({ path: '/trade-fair-service/ditf/trade-fair-management/return-refund-data-list', query: { stall_cat_id: item.id } })
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, returnRefundList, params)
      if (result.success) {
        this.selectedItem = []
        const listData = result.data.data.map((item, index) => {
          const stallTypeObj = this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.find(el => el.value === parseInt(item.stall_type))
          const stallTypeData = {
            stall_type_bn: stallTypeObj !== undefined ? stallTypeObj.text_bn : '',
            stall_type_en: stallTypeObj !== undefined ? stallTypeObj.text_en : ''
          }

          const priceTypeObj = this.$store.state.TradeFairService.ditfCommonObj.priceTypeList.find(el => el.value === parseInt(item.price_type))
          const priceTypeData = {
            price_type_bn: priceTypeObj !== undefined ? priceTypeObj.text_bn : '',
            price_type_en: priceTypeObj !== undefined ? priceTypeObj.text_en : ''
          }
          return Object.assign({}, item, { serial: index }, stallTypeData, priceTypeData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
